
<template>
  <div class="page-box box">
    <div class="none-box box">
      <van-empty
        class="none-image"
        :image="require('@/assets/img/none-img.png')"
        description="您还未绑定银行卡，请先添加银行卡。"
      />
    </div>
    <van-button
      class="_btn"
      icon="plus"
      color="#51B464"
      round
      type="info"
      @click="$router.push('/user/bankInfo')"
    >
      添加银行卡
    </van-button>
  </div>
</template>

<script>
export default {
  name: 'BankIndex',
  components: {
  },
  data () {
    return {
      isGlobalPage: false
    }
  },
  computed: {
  },
  created () {
    this.getBankList()
  },
  methods: {
    async getBankList () {
      let res = await this.$http.userApi.bankList({
        pageNo: 1,
        pageSize: 999
      })
      console.log(res, 'res')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  .none-box {
    padding-top: 135px;
    .none-image {
      /deep/ img {
        widows: 150px;
        height: 115px;
      }
    }
  }
  ._btn {
    width: 200px;
    height: 40px;
    color: #fff;
    position: fixed;
    left: 50%;
    bottom: 16px;
    margin-left: -100px;
  }
}
</style>
